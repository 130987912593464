// modules
import VueRecaptcha from 'vue-recaptcha'

// service
import AthLoginService from '@/services/api/Auth/AthLogin'
import AthRegisterService from '@/services/api/Auth/AthRegister'
import GRegionService from '@/services/api/General/GRegion'
import PCategoryService from '@/services/api/Provider/PCategory'
import PProviderService from '@/services/api/Provider/PProvider'
import GUserAccountService from '@/services/api/General/GUserAccount'
import PSizeService from '@/services/api/Provider/PSize'
import PSubcategoryService from '@/services/api/Provider/PSubcategory'
import RulesService from '@/services/rules'

// sign view
export default {
  name: 'v-view-sign',
  components: {
    VueRecaptcha,
  },
  data() {
    return {
      acceding: false,
      busy: {
        email: false,
        registry: false,
        rut: false,
        searching: false,
        subcategory: false,
      },
      athRegister: {},
      subcategories: [],
      siiProvider: {
        status: 500,
      },
      dialogs: {
        success: false,
      },
      gCommunes: [],
      gProvinces: [],
      rules: {
        email: true,
        rut: true,
      },
      pProvider: null,
      pSubcategories: [],
    }
  },
  methods: {

    /**
     * 
     */
    async getProvider(pProviderId) {
      await PProviderService.getById(pProviderId).then(pProvider => {
        return this.$store.dispatch('ProviderModule/setData', {
          data: pProvider,
        })
      })
    },

    /**
     * 
     */
    handleBusinessSizeFetch() {
      return PSizeService.getAll()
    },

    /**
     * 
     */
    handleBusinessTypeFetch() {
      return PCategoryService.getAll()
    },

    /**
     * 
     */
    handleSubcategoryFetch() {
      return PSubcategoryService.getAllPaginateByCategoryId(this.athRegister.categoryId)
    },

    /**
     * 
     */
    handleCommuneFetch() {
      return Promise.resolve({
        data: this.gCommunes,
      })
    },

    /**
     * 
     */
    async handleDialogClick() {
      this.acceding = true
      await AthLoginService.login(this.athRegister.login()).then(this.handleLoginSuccess).catch(this.handleLoginError).finally(this.handleLoginFinally)
      this.acceding = false
    },

    /**
     * 
     */
    handleFormError() {
      this.$toast.error('El formulario de registro posee errores. Se deben resolver antes de continuar.')
    },

    /**
     * Called when the form emit reset
     */
    handleFormReset() {
      this.athRegister = AthRegisterService.toRegister()
    },

    /**
     * Called when the form emit submit
     */
    async handleFormSubmit() {
      if (this.athRegister['g-recaptcha-response']) {
        this.handleFormVerified()
        return 
      }
      this.$refs.recaptcha.execute()
    },

    /**
     * 
     */
    async handleFormVerified() {
      this.busy.registry = true
      await AthRegisterService.register(this.athRegister).then(this.handleRegisterSuccess).catch(this.handleRegisterError)
      window.lintrk('track', { conversion_id: 15269434 });
      this.busy.registry = false
    },

    /**
     * 
     */
    handleLoginError(payload) {
      AthLoginService.translate(payload).then(this.$toast.error)
    },

    /**
     * 
     */
    handleLoginFinally() {
      this.acceding = false
      this.dialogs.success = false
    },

    /**
     * 
     */
    async handleLoginSuccess(payload) {

      // set auth data
      await this.$store.dispatch('AuthModule/setData', {
        data: {
          ...payload.data,
          userAccount: undefined,
        }
      })

      // set user data
      await this.$store.dispatch('UserModule/setData', {
        data: payload.data.userAccount
      })

      // get provider
      if (payload.data.userAccount.profile.code === 'provider') {
        await this.getProvider(payload.data.userAccount.profile.relatedId)
      }

      // set subcategories
      await PProviderService.subcategories(this.pProvider)

      // redirect to
      if (payload.data.userAccount.profile.code === 'provider') {
        this.$router.push('/perfil/proveedor')
      } else {
        this.$router.push('/')
      }
    },

    /**
     * 
     */
    handleProvinceFetch() {
      return Promise.resolve({
        data: this.gProvinces,
      })
    },

    /**
     * 
     */
    handleRecaptchaVerify(res) {
      console.log({ res })
      this.athRegister['g-recaptcha-response'] = res
      this.handleFormVerified()
    },

    /**
     * 
     */
    handleRegionFetch() {
      return GRegionService.getAllWithCommune()
    },

    /**
     * 
     */
    handleRegisterError(payload) {
      AthRegisterService.translate(payload).then(this.$toast.error)
    },

    /**
     * 
     */
    handleRegisterSuccess(res) {
      this.pProvider = PProviderService.shape({
        id: res.data.providerId
      })
      this.pProvider.subcategories = this.subcategories.map(id => ({ id }))
      this.dialogs.success = true
    },

    /**
     * 
     */
    handleRepeatPasswordRule() {
      return this.athRegister.has_equal_passwords || 'Las contraseñas deben ser las mismas.'
    },

    /**
     * 
     */
    handleRutBlur() {
      this.handleRutVerify()
      if (this.athRegister.is_provider) {
        this.handleRutSearch()
      }
    },

    /**
     * 
     */
    async handleRutSearch() {
      this.busy.searching = true
      if (!this.siiProvider.data || this.siiProvider.data.rut !== this.athRegister.rut) {
        this.siiProvider = await PProviderService.search({ rut: this.athRegister.rut }).catch(e => e.response)
      }
      this.busy.searching = false
    },

    /**
     * 
     */
    async handleRutVerify() {
      this.busy.rut = true
      await GUserAccountService.verify({ rut: this.athRegister.rut }).then(res => {
        this.rules.rut = !res.data.isRegistered
      })
        .catch(console.log)
      this.busy.rut = false
    },

    /**
     * 
     */
    handleRutRule() {
      return this.rules.rut || 'Rut ya registrado'
    },

    /**
     * 
     */
    async handleEmailBlur(event) {
      this.busy.email = true
      await GUserAccountService.verify({ email: event.target.value }).then(res => {
        this.rules.email = !res.data.isRegistered
      })
        .catch(console.log)
      this.busy.email = false
    },

    /**
     * 
     */
    handleEmailRule() {
      return this.rules.email || 'Email ya registrado'
    },

    /**
     * 
     */
    handleSIIProviderClick() {
      if (this.siiProvider.status === 200) {
        this.$confirm('Se encontraron datos del proveedor en el SII. ¿para cargar los datos obtenidos?', 'confirmar', () => {
          this.handleConfirmSIIProvider()
          this.$toast.getCmp().close()
        })
      }
    },

    /**
     * 
     */
    handleConfirmSIIProvider() {
      this.athRegister.businessName = this.siiProvider.data.businessName
      this.athRegister.email = this.siiProvider.data.email
      this.athRegister.phone = this.siiProvider.data.phone
      this.athRegister.address = this.siiProvider.data.address
      this.$toast.success('Datos cargados.')
    },
  },
  created() {
    this.handleFormReset()
  },
  props: {

    /**
     * 
     */
    addressRules: {
      type: Array,
      default: () => [
        RulesService.required,
        RulesService.notEmpty,
        RulesService.max(255),
      ],
    },

    /**
     * 
     */
    businessNameRules: {
      type: Array,
      default: () => [
        RulesService.required,
        RulesService.max(180),
      ],
    },

    /**
     * 
     */
    businessSizeRules: {
      type: Array,
      default: () => [
        RulesService.required,
        RulesService.select,
      ],
    },

    /**
     * 
     */
    businessTypeRules: {
      type: Array,
      default: () => [
        RulesService.required,
        RulesService.select,
      ],
    },

    /**
     * 
     */
    communeRules: {
      type: Array,
      default: () => [
        RulesService.required,
        RulesService.select,
      ],
    },

    /**
     * 
     */
    descriptionRules: {
      type: Array,
      default: () => [
        RulesService.required,
        RulesService.max(4000),
      ],
    },

    /**
     * 
     */
    emailRules: {
      type: Array,
      default: () => [
        RulesService.required,
        RulesService.email,
        RulesService.max(40),
      ],
    },

    /**
     * 
     */
    nameRules: {
      type: Array,
      default: () => [
        RulesService.required,
        RulesService.min(8),
        RulesService.max(255),
      ],
    },

    /**
     * 
     */
    passwordRules: {
      type: Array,
      default: () => [
        RulesService.required,
        RulesService.min(6),
        RulesService.max(20),
      ],
    },

    /**
     * 
     */
    phoneRules: {
      type: Array,
      default: () => [
        RulesService.required,
        RulesService.length(9),
        RulesService.numeric,
      ],
    },

    /**
     * 
     */
    provinceRules: {
      type: Array,
      default: () => [
        RulesService.required,
        RulesService.select,
      ],
    },

    /**
     * 
     */
    reCaptchaSiteKey: {
      type: String,
      default: process.env.VUE_APP_GOOGLE_RECAPTCHA_KEY
    },

    /**
     * 
     */
    regionRules: {
      type: Array,
      default: () => [
        RulesService.required,
        RulesService.select,
      ],
    },

    /**
     * 
     */
    repeatPasswordRules: {
      type: Array,
      default: () => [
        RulesService.required,
        RulesService.min(6),
        RulesService.max(20),
      ],
    },

    /**
     * 
     */
    rutRules: {
      type: Array,
      default: () => [
        RulesService.required,
        RulesService.rut,
        RulesService.max(12),
      ],
    },

    /**
     * 
     */
    subcategoryRules: {
      type: Array,
      default: () => [
        RulesService.required,
        // RulesService.rut,
        // RulesService.max(12),
      ],
    },

    /**
     * 
     */
    webUrlRules: {
      type: Array,
      default: () => [
        // RulesService.required,
        RulesService.url,
        RulesService.max(120),
      ],
    },
  },
  watch: {

    /**
     * 
     */
    'athRegister.rut'() {
      this.rules.rut = true
    },

    /**
     * 
     */
    'athRegister.email'() {
      this.rules.email = true
    },

    // categoría
    async 'athRegister.categoryId'(categoryId) {
      this.busy.subcategory = true
      PSubcategoryService.getAllPaginateByCategoryId(categoryId).then(res => {
        this.subcategories = []
        this.pSubcategories = res.data
      })
      this.busy.subcategory = false
    },

    /**
     * 
     */
    'athRegister.regionId'(regionId) {
      if (regionId) {
        this.gProvinces = this.getRef('provinceId') ? this.getRef('regionId').getSelectedItem().getProvinces() : []
      }
    },

    /** 
     * 
     */
    'athRegister.provinceId'(provinceId) {
      if (provinceId) {
        this.gCommunes = this.getRef('provinceId') ? this.getRef('provinceId').getSelectedItem().getCommunes() : []
      }
    },

    /**
     * 
     */
    'gProvinces'() {
      this.athRegister.provinceId = null
      this.athRegister.communeId = null
      this.getRef('provinceId').refresh()
    },

    /**
     * 
     */
    'gCommunes'() {
      this.athRegister.communeId = null
      this.getRef('communeId').refresh()
    },

    /**
     * 
     */
    'siiProvider'() {
      this.handleSIIProviderClick()
    },
  },
}